import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from 'react-modal'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { dispatch, useSelector } from '../../../../../redux/store'
import {
  selectInitialProfile,
  selectReceivePayment,
} from '../../../../../redux/selector'
import {
  clearState,
  closeModal,
  openModal,
} from '../../../slices/receive-payment'
import ReceiveBank from './ReceiveBank'
import ReceiveDate from './ReceiveDate'
import ReceiveTime from './ReceiveTime'
import ReceiveRemark from './ReceiveRemark'
import {
  loadingDialog,
  openDialog,
} from '../../../../../components/dialog/slices'
import { callAPI } from '../../../../../utils/callAPI'
import { CustomModalStyle } from '../../../../../components/CustomModal/CustomModalStyle'
import { getTitleNotOwner } from '../../handler/getTitleNotOwner'
import _ from 'lodash'

export const updatePaymentUrl = '/fullform/update/payment-status'

type ChildComponentProps = {
  fullFormUuid: string
}

export default function ReceivePayment(props: ChildComponentProps) {
  const theme = useTheme()
  const receivePayment = useSelector(selectReceivePayment)
  const initProfile = useSelector(selectInitialProfile)

  const onCloseModal = () => {
    dispatch(clearState())
  }
  const title = getTitleNotOwner(initProfile, 'ยืนยันบันทึกข้อมูล')
  const onSubmit = () => {
    dispatch(closeModal())
    dispatch(
      openDialog({
        title: title,
        message: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        isCloseDialog: false,
        handleCancel: () => {
          dispatch(openModal())
        },
        handleConfirm: async () => {
          dispatch(loadingDialog())
          await callAPI({
            url: updatePaymentUrl,
            method: 'POST',
            body: {
              fullFormUuid: props.fullFormUuid,
              receiveDate: receivePayment.receiveDate,
              receiveTime: receivePayment.receiveTime,
              receiveRemark: receivePayment.receiveRemark,
              receiveBank: receivePayment.receiveBank,
            },
          })
          dispatch(
            openDialog({
              type: 'success',
              title: 'สำเร็จ',
              message: 'บันทึกสำเร็จ',
              isCloseDialog: false,
              handleConfirm: () => window.location.reload(),
            })
          )
        },
      })
    )
  }

  const isDisabledBtn = () => {
    return (
      _.isEmpty(receivePayment.receiveBank) ||
      _.isEmpty(receivePayment.receiveDate) ||
      _.isEmpty(receivePayment.receiveTime) ||
      receivePayment.receiveDate === 'Invalid Date' ||
      receivePayment.receiveTime === 'Invalid Date'
    )
  }

  return (
    <Modal
      isOpen={receivePayment.isShowModal}
      onRequestClose={onCloseModal}
      style={CustomModalStyle(theme)}
    >
      <>
        <Box padding={2}>
          <Typography variant="h6">รับเงิน</Typography>
        </Box>
        <Stack padding={2} pt={0} gap={2}>
          <ReceiveBank />
          <Box display="flex" gap={2} flexDirection="row">
            <ReceiveDate />
            <ReceiveTime />
          </Box>
          <ReceiveRemark />

          <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
            <Button variant="outlined" onClick={onCloseModal}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              disabled={isDisabledBtn()}
              onClick={onSubmit}
            >
              ยืนยัน
            </Button>
          </Stack>
        </Stack>
      </>
    </Modal>
  )
}
