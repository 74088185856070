import { Breakpoint, SxProps } from '@mui/material'
import React from 'react'
import { DIALOG_TYPE_STRING } from '../../../dialog/constants'

export interface CustomDialogState {
  isOpen?: boolean
  isLoading?: boolean
  maxWidth?: Breakpoint
  type?: string
  title?: string
  message?: string
  content?: React.ReactNode
  textConfirmButton?: string
  textCancelButton?: string
  textOKButton?: string
  handleConfirm?: (() => void) | null
  handleCancel?: (() => void) | null
  handleOK?: (() => void) | null
  showButton?: boolean
  disabledButton?: boolean
  disabledCancel?: boolean
  boxSx?: SxProps
  showCloseButton?: boolean
}

export const initialState: CustomDialogState = {
  isOpen: false,
  isLoading: false,
  maxWidth: 'sm',
  type: DIALOG_TYPE_STRING.DEFAULT,
  title: '',
  message: '',
  content: null,
  textConfirmButton: '',
  textCancelButton: '',
  textOKButton: '',
  handleConfirm: null,
  handleCancel: null,
  handleOK: null,
  showButton: true,
  disabledButton: false,
  disabledCancel: false,
  showCloseButton: false,
}
