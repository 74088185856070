import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp'
import Link from '@mui/material/Link'
import _ from 'lodash'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import CustomButton from '../../../../components/Input/CustomButton'
import { AppDispatch } from '../../../../redux/store'
import { themeColor } from '../../../../styles/themes/themeData'
import {
  ageCalculator,
  displayDateTime,
} from '../../../../utils/convertFormatDateTime'
import { getValueStr } from '../../../../utils/getValue'
import { setLineClamp } from '../../../../utils/lineClamp'
import { FullFormMemo } from '../slices/model'
import { FULLFORM } from '../constants/fullForm'
import { isMobile, isTablet } from 'react-device-detect'
import { PUBLIC_URL } from '../../../../constants/webMeta'
import { DateFormat } from '../../../../constants/dateFormat'
import { openCustomDialog } from '../../../../components/CustomDialog/slices'

export const openAlertMemoContent =
  (data: FullFormMemo) => async (dispatch: AppDispatch) => {
    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CUSTOM,
        content: <AlertMemoContent data={data} />,
        showCloseButton: true,
      })
    )
  }
export const AlertMemoContent = (props: { data: FullFormMemo }) => {
  const { data } = props
  const genderInfo = _.find(FULLFORM.gender, {
    value: data.personalInfo.gender,
  })
  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h4">
        รายการแก้ไขใบสมัคร
      </Typography>

      <Typography
        sx={{ mb: 1, color: themeColor.grey.grey500 }}
        textAlign={'left'}
        variant="body2"
        color={'text.gray'}
      >
        เมื่อ :{' '}
        {displayDateTime(
          getValueStr(data.memo, 'updatedAt'),
          DateFormat.DDMMYYYYHHmm
        )}{' '}
        น.
      </Typography>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item textAlign={'left'} sm={8}>
          <Box display={'flex'}>
            <Box>
              <Avatar
                sx={{ width: '40px', height: '40px' }}
                src={`${PUBLIC_URL}/icons/profile-def.png`}
              />
            </Box>
            <Box>
              <Typography sx={{ ml: 1, ...setLineClamp(1) }} variant="h6">
                {data.personalInfo.firstName} {data.personalInfo.lastName}
              </Typography>
              <Typography sx={{ ml: 1 }} variant="body2">
                {getValueStr(genderInfo, 'label') !== '' && (
                  <>
                    เพศ <strong>{getValueStr(genderInfo, 'label')} </strong>
                  </>
                )}
                อายุ{' '}
                <strong>{ageCalculator(data.personalInfo.dateOfBirth)}</strong>{' '}
                ปี
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item textAlign={'center'} sm={4}>
          <Box display={'flex'}>
            {isMobile || isTablet ? (
              <Link href={`tel:${data.personalInfo.mobileNo}`}>
                <CustomButton
                  size={'small'}
                  variant={'outlined'}
                  sx={{ pl: 1 }}
                >
                  <PhoneSharpIcon fontSize={'small'} sx={{ p: 0.25 }} />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {data.personalInfo.mobileNo}
                  </Typography>
                </CustomButton>
              </Link>
            ) : (
              <Box display={'flex'}>
                <Typography variant="body2" color={'text.gray'}>
                  เบอร์โทร:
                </Typography>
                <Typography variant="body2" sx={{ pl: 0.5 }}>
                  {data.personalInfo.mobileNo}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ py: 1 }}>
        <Typography color={'text.gray'}>รายการแก้ไข</Typography>
      </Box>
      <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
        {data.memo.map((memo, index) => (
          <Box
            key={index}
            sx={{ borderBottom: 'solid 2px #F1F1F1', pb: 2, mb: 2 }}
          >
            <Box sx={{ display: 'inline-block' }}>
              <Typography
                sx={{
                  backgroundColor: themeColor.warning.light,
                  borderRadius: 3,
                  px: 1.5,
                  ...setLineClamp(1),
                }}
                variant="body2"
              >
                {memo.reason}
              </Typography>
            </Box>

            <Typography sx={{ pt: 1 }} variant="body2">
              <strong>เหตุผล : </strong>
              {memo.reasonForMA}
            </Typography>
            {memo.detail && (
              <Typography variant="body2">
                <strong>รายละเอียด : </strong> {memo.detail}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </>
  )
}
