import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { DateFormat } from '../constants/dateFormat'

type TimeUnit = 'hour' | 'day' | 'minute'

type ConvertFormatDateTimeParams = {
  value: string | null
  type: string
}

// Extend dayjs with the necessary plugins
dayjs.extend(utc)
dayjs.extend(timezone)

export const convertFormatDateTime = ({
  value,
  type,
}: ConvertFormatDateTimeParams): string => {
  if (value === null) return '-'
  dayjs.extend(utc)
  dayjs.extend(timezone)

  let result = '-'
  if (type === 'date') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_SHOW)
  }

  if (type === 'dateTime') {
    // result =
    //   dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_SHOW) +
    //   ' ' +
    //   dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_TIME)
    result = dayjs(value).utc().format('DD/MM/YYYY HH:mm')
  }

  if (type === 'time') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'dayMonth') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_DAY_MONTH)
  }

  if (type === 'dateDb') {
    result = dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_DB)
  }

  return result
}

export const convertFormatDateTimeV2 = (
  value: Date | string | null | undefined,
  type?: string | DateFormat
): string => {
  if (value === null || value === undefined) return ''
  if (!dayjs(value).isValid()) return ''

  // Use dayjs.utc for UTC values, otherwise use dayjs
  const dateHandler = dayjs.utc

  if (!type) type = DateFormat.DDMMYYYY

  let result = '-'
  switch (type) {
    case 'date':
      result = dateHandler(value).format(DateFormat.DDMMYYYY)
      break
    case 'dateTime':
      result = dateHandler(value).format(DateFormat.DDMMYYYYHHmm)
      break
    case 'time':
      result = dateHandler(value).format(DateFormat.HHmm)
      break
    case 'dayMonth':
      result = dateHandler(value).format(DateFormat.DDMM)
      break
    case 'dateDb':
      result = dateHandler(value).format(DateFormat.YYYYMMDD)
      break
    case 'dateTimeDisplay':
      result =
        dateHandler(value).format(DateFormat.DDMMYYYY) +
        ',' +
        dateHandler(value).format(DateFormat.HHmm) +
        ' น.'
      break
    case 'dateDisplay':
      result = dateHandler(value).format(DateFormat.DDMMYYYY)
      break
    case 'timeDisplay':
      result = dateHandler(value).format(DateFormat.HHmm) + ' น.'
      break
    default:
      result = dateHandler(value).format(type)
      break
  }

  return result
}

export const calculateAge = (birthDate: string | null | undefined): number => {
  if (birthDate === null || birthDate === undefined) return 0
  if (!dayjs(birthDate).isValid()) return 0
  const birth = dayjs(birthDate)
  const today = dayjs()
  return today.diff(birth, 'year')
}

export const calculateDuration = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  unit: TimeUnit
): number => {
  if (startDate === null || startDate === undefined) return 0
  if (!dayjs(startDate).isValid()) return 0
  if (endDate === null || endDate === undefined) return 0
  if (!dayjs(endDate).isValid()) return 0

  const start = dayjs(startDate)
  const end = dayjs(endDate)
  const duration = end.diff(start, unit)
  return duration
}
export const showDayOrTime = (dateEnd: Date | string | null | undefined) => {
  const toDay = getCurrentDateTime().format(DateFormat.YYYYMMDDHHMMSSSSS)
  // Convert dateEnd to UTC timezone before formatting
  if (dateEnd) {
    dateEnd = dayjs.utc(dateEnd).format(DateFormat.YYYYMMDDHHMMSSSSS)
  }

  const d = calculateDuration(toDay, dateEnd, 'day')
  const h = calculateDuration(toDay, dateEnd, 'hour')
  const m = calculateDuration(toDay, dateEnd, 'minute')
  if (d > 0) return d + ' วัน'
  else if (h > 0) return h + ' ชั่วโมง'
  else {
    if (m < 0) return 'หมดเวลา'
    else return m + ' นาที'
  }
}
export const convertPercentTime = (min: number, total: number) => {
  return (min * 100) / total
}
export const getPercentTime = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined
) => {
  const today = getCurrentDateTime().format(DateFormat.YYYYMMDDHHMMSSSSS)
  startDate = dayjs.utc(startDate).format(DateFormat.YYYYMMDDHHMMSSSSS)
  endDate = dayjs.utc(endDate).format(DateFormat.YYYYMMDDHHMMSSSSS)

  const use = calculateDuration(startDate, today, 'minute')
  const total = calculateDuration(startDate, endDate, 'minute')
  const percent = convertPercentTime(use, total)

  if (percent < 0) return 0
  else if (percent > 100) return 100
  else return percent
}

export const addDay = (date: Date | string | null | undefined, num: number) => {
  const day = dayjs(date)
  return day.add(num, 'day').toISOString()
}

export const getDateNow = () => {
  const today = dayjs().tz('Asia/Bangkok').toString()
  return convertFormatDateTimeV2(today, DateFormat.YYYYMMDDHHMMSSSSS)
}

export function getCurrentDateTime(date?: string) {
  if (date) return dayjs(date).utc().add(7, 'hour')
  return dayjs().utc().add(7, 'hour')
}

export const ageCalculator = (date: string | null) => {
  const birthdate = dayjs(date)
  const today = dayjs()
  const age = today.diff(birthdate, 'year')
  return age
}
export const displayDateTime = (
  value: Date | string | Dayjs | null | undefined,
  type: DateFormat = DateFormat.DDMMYYYY
): string => {
  if (value === null || value === undefined) return '-'
  if (!dayjs(value).isValid()) return '-'

  const dateHandler = dayjs.utc

  return dateHandler(value).add(543, 'year').format(type)
}

export const displayDateTimeV2 = (
  value: Date | string | null | undefined,
  type: string = 'DD/MM/YYYY'
): string => {
  if (!value) return '-'
  if (!dayjs(value).isValid()) return '-'

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return dayjs(value).tz(localTimeZone).add(543, 'year').format(type)
}
